import React from "react";
import Lottie from "lottie-react";

import Content1AnimationData from "./content1.json";
import Content2AnimationData from "./content2.json";
import Content3AnimationData from "./content3.json";
import Content4AnimationData from "./content4.json";
const ContentAnimation = ({ section }: any) => {
  let animationData: any = Content1AnimationData;
  switch (section) {
    case "home":
      animationData = Content3AnimationData;
      break;
    case "services":
      animationData = Content2AnimationData;
      break;
    case "contact":
      animationData = Content3AnimationData;
      break;
    // case "iruya":
    //   animationData = iruyaAnimationData;
    //   break;
    default:
      break;
  }
  return (
    <Lottie
      //loop={false}
      animationData={animationData}
      //style={{ position: "absolute", width: "100%", height: "100%" }}
      style={{ width: 300, height: 133 }}
    />
  );
};

export { ContentAnimation };
