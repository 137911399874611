import React, { useState, useEffect } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import {
  COLOR_DANGER,
  COLOR_DARK,
  COLOR_LIGHT,
  COLOR_MEDIUM,
  COLOR_SUCCESS,
  COLOR_TEXT,
  COLOR_TEXT_SHADE,
  COLOR_TEXT_TINT,
} from "./constants";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";

import { project } from "./config/project";

import { HomePage } from "./pages/HomePage/HomePage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { ServicesPage } from "./pages/ServicesPage/ServicesPage";

function App() {
  const [primaryColor, setPrimaryColor] = React.useState(project.colorPrimary);

  React.useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--color-primary", project.colorPrimary);
    root?.style.setProperty("--color-primary-tint", project.colorPrimaryTint);
    root?.style.setProperty("--color-primary-shade", project.colorPrimaryShade);
    root?.style.setProperty("--color-dark", COLOR_DARK);
    root?.style.setProperty("--color-medium", COLOR_MEDIUM);
    root?.style.setProperty("--color-light", COLOR_LIGHT);
    root?.style.setProperty("--color-text", COLOR_TEXT);
    root?.style.setProperty("--color-text-shade", COLOR_TEXT_SHADE);
    root?.style.setProperty("--color-text-tint", COLOR_TEXT_TINT);
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        shadows: shadows.map(() => "none"),
        palette: {
          text: {
            primary: COLOR_TEXT,
          },
          background: {
            default: COLOR_LIGHT,
          },
          primary: { main: primaryColor },
          warning: {
            main: COLOR_DANGER,
          },
          success: {
            main: COLOR_SUCCESS,
          },
        },
      } as any),
    [primaryColor]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
